import React from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

import RoadmapItem from '~/components/RoadmapItem';
import Modal from '~/components/Modal';
import Form from '~/components/Form';
import { ReactComponent as IconClose } from '@/icons/ic_x.svg';

const MobileContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  min-height: calc(100vh - 90px);
`;

const FormWrapper = styled.div`
  width: ${isMobile ? '100%' : '480px'};

  & > div {
    width: 100%;
  }
`;

const FormContent = styled.div`
  margin: 5px 10px;
  text-align: left;

  & > div {
    pointer-events: none;
  }
`;

const RoadmapItemModalCloseButton = styled.div`
  position: absolute;
  right: 13px;
  top: 14px;
  width: 14px;
  height: 14px;
  color: #5dc6f6;
  cursor: pointer;

  &:hover {
    color: #fff;
  }
`;

const RoadmapItemModal = ({ onClose, ...rest }) => (
  <Modal onClose={isMobile && onClose} isBlurred>
    <MobileContainer>
      <FormWrapper>
        <Form>
          {isMobile ? null : (
            <RoadmapItemModalCloseButton onClick={onClose}>
              <IconClose />
            </RoadmapItemModalCloseButton>
          )}
          <FormContent>
            <RoadmapItem {...rest} />
          </FormContent>
        </Form>
      </FormWrapper>
    </MobileContainer>
  </Modal>
);

export default RoadmapItemModal;
