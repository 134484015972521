/**
 * Membership Landing App
 */
import React from 'react';
import { hot } from 'react-hot-loader';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import {
  ErrorBoundaryReporter,
  DataProvider,
  AppProvider,
  Auth,
} from '~/features/App';
import AsyncMain from '~/features/Main/AsyncMain';
import Modals from '~/features/App/Modals';
import AsyncFaq from '~/features/Faq';
import AsyncPrivacy from '~/features/Privacy';
import AsyncTerms from '~/features/Terms';
import AsyncRoadmap from '~/features/Roadmap';
import AsyncCitizenRules from '~/features/CitizenRules';
import AsyncMerchantRules from '~/features/MerchantRules';
import AsyncAnnouncements from '~/features/Announcements';

/**
 * App React-component
 * @returns {React.Node<*>} React-component
 */
const App = () => (
  <ErrorBoundaryReporter>
    <DataProvider>
      <AppProvider>
        <BrowserRouter>
          <React.Fragment>
            <Modals />
            <Auth>
              {({ loggedInUser, loading }) => (
                <Switch>
                  <Route
                    path="/"
                    exact
                    render={props => (
                      <AsyncMain
                        loggedInUser={loggedInUser}
                        loading={loading}
                        {...props}
                      />
                    )}
                  />
                  <Route path="/:type(faq|rules)" component={AsyncFaq} />
                  <Route path="/privacy" component={AsyncPrivacy} />
                  <Route path="/terms" component={AsyncTerms} />
                  <Route path="/citizen-rules" component={AsyncCitizenRules} />
                  <Route
                    path="/merchant-rules"
                    component={AsyncMerchantRules}
                  />
                  <Route path="/roadmap" component={AsyncRoadmap} />
                  <Route
                    path="/announcements/:id?"
                    component={AsyncAnnouncements}
                  />
                  <Redirect to="/" />
                </Switch>
              )}
            </Auth>
          </React.Fragment>
        </BrowserRouter>
      </AppProvider>
    </DataProvider>
  </ErrorBoundaryReporter>
);

export default hot(module)(App);
