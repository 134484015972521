import * as R from 'ramda';
import urlParser from 'js-video-url-parser';
import { stringify } from 'query-string';

export const mergeDeepRightAll = R.unapply(R.reduce(R.mergeDeepRight, {}));
export const renameKeys = R.curry((keysMap, obj) =>
  R.reduce(
    (acc, key) => {
      const mappedKey = keysMap[key] || key;

      if (Array.isArray(mappedKey)) {
        return R.pipe(
          R.assoc(mappedKey[0], R.path([key, 0], obj)),
          R.assoc(mappedKey[1], R.path([key, 1], obj)),
        )(acc);
      }

      return R.assoc(mappedKey, obj[key], acc);
    },
    {},
    R.keys(obj),
  ),
);

const isWindow = obj => {
  if (typeof window.constructor === 'undefined') {
    return obj instanceof window.constructor;
  }

  return !R.isNil(obj) && obj.window === obj;
};

export const openInNewTab = (url, newTab) => {
  if (isWindow(newTab)) {
    newTab.location = url;
  } else {
    window.open(url, '_blank');
  }
};

export const prepareUrl = locale => platform => {
  const paramsHandlers = {
    BTC_TREASURE_CHEST: locale => ({
      locale: R.cond([[R.equals('en'), R.always('en-US')], [R.T, R.identity]])(
        locale,
      ),
    }),
    JEMJAR: locale => ({
      locale: R.cond([[R.equals('en'), R.always('en-US')], [R.T, R.identity]])(
        locale,
      ),
    }),
    MONOPOLY: locale => ({
      locale: R.cond([
        [R.equals('zh-CN'), R.always('zhHans')],
        [R.T, R.identity],
      ])(locale),
    }),
    MS: locale => ({ locale }),
  };

  const pathnameHandlers = {
    SUPPORT: locale =>
      R.cond([[R.equals('zh-CN'), R.always('zh-hans')], [R.T, R.always('')]])(
        locale,
      ),
    FSB: locale =>
      R.cond([[R.equals('zh-CN'), R.always('cn')], [R.T, () => '']])(locale),
    GAMES: locale =>
      R.cond([[R.equals('zh-CN'), R.always('zh-cn')], [R.T, R.identity]])(
        locale,
      ),
    WENDA: locale =>
      R.cond([[R.equals('zh-CN'), R.always('zh')], [R.T, R.identity]])(locale),
  };

  const params = paramsHandlers[platform]
    ? `?${stringify(paramsHandlers[platform](locale))}`
    : '';

  // NOTE: slash at the pathname end is important
  const pathname = pathnameHandlers[platform]
    ? `/${pathnameHandlers[platform](locale)}/`
    : '';

  return `${
    process.env[`REACT_APP_${platform}_PLATFORM_ENDPOINT`]
  }${pathname}${params}`.replace(/\/+$/, '');
};

/**
 * Function that make redirect or show modal for ms platform applications
 * @param {string} locale - selected locale in the app
 * @returns {undefined}
 */
export const goToPlatform = locale => platform => newTab => {
  const url = prepareUrl(locale)(platform);

  openInNewTab(url, newTab);
};

export const getCorrectProtocol = url => {
  if (url && window.location.protocol === 'https:') {
    return url.replace(/^(http:)?\/\//, 'https://');
  }

  return url;
};

export const prepareVideoURI = media =>
  R.tryCatch(
    R.pipe(
      urlParser.parse,
      // eslint-disable-next-line
      R.assoc('videoInfo', R.__, {
        format: 'embed',
        params: 'internal',
      }),
      urlParser.create,
      getCorrectProtocol,
    ),
    R.F,
  )(media);

export const mapIndexed = R.addIndex(R.map);

const isTruthyLowerCase = R.pipe(
  R.toLower,
  R.equals('true'),
);

export const parseBool = R.cond([
  [R.is(Boolean), R.identity],
  [R.isNil, R.F],
  [R.T, isTruthyLowerCase],
]);

export const getLanguageFromLocale = R.cond([
  [R.equals('en'), R.always('en-US')],
  [R.equals('zh'), R.always('zh-CN')],
  [R.T, R.identity],
]);

export const getLocaleFromLanguage = R.cond([
  [R.equals('en-US'), R.always('en')],
  [R.T, R.identity],
]);
