/*
 * CityIcon messages
 *
 * This contains all the strings for the CityIcon component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  // Deal Plus title button
  deal_plus: 'Deal+',
  // FSB title button
  fsb: 'Offline Products',
  // Game Platform title button
  games: 'Online Game',
  // Jemjar title button
  jemjar: 'Offline Service',
  // Monopoly title button
  monopoly: 'GRC • Monopoly',
  // Membership system icon
  ms: 'MemberShip',
  // Show tutorial video button
  video: 'Video Guide',
  // Wenda title button
  wenda: 'Marketing Survey',
  // GRC Chat title button
  chat: 'GRC Chat',
});
