import React, { Fragment } from 'react';
import styled, { css } from 'styled-components/macro';
import { FormattedMessage } from 'react-intl';
import { isMobile } from 'react-device-detect';
import { ifProp } from 'styled-tools';
import * as R from 'ramda';

import Modal from '~/components/Modal';
import Form from '~/components/Form';
import Ellipsis from '~/components/Ellipsis';
import DataValue from '~/components/DataValue';
import { ReactComponent as IconClose } from '@/icons/ic_x.svg';

import Score from '~/components/Score';

import messages from './messages';

const MobileContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: calc(100vh - 90px);
`;

const FormWrapper = styled.div`
  display: flex;
  width: ${isMobile
    ? 'auto'
    : ifProp('isMerchantRankingList', '480px', '934px')};
  max-height: 100%;

  & > div {
    width: 100%;
    flex: 1;
  }

  h4 {
    padding: 16px 10px 10px 37px;
    text-align: left;

    ${isMobile
      ? css`
          font-size: 20px;
          font-weight: bold;
          line-height: 24px;
        `
      : css`
          font-size: 24px;
          font-weight: 500;
          line-height: 28px;
        `};
  }

  .react-form-content {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    box-sizing: border-box;
  }
`;

const FormContent = styled.div`
  display: flex;
  margin: 5px 10px;
  text-align: left;
  max-height: 100%;
  overflow: hidden;
  overflow-y: auto;
`;

const CloseButton = styled.div`
  position: absolute;
  right: 13px;
  top: 14px;
  width: 14px;
  height: 14px;
  color: #5dc6f6;
  cursor: pointer;

  &:hover {
    color: #fff;
  }
`;

const ScoreModal = ({ onClose, name, data }) => {
  const btcRankingLists = [
    'btcVoucherRankingList',
    'monthlyBTCVoucherEarnings',
  ];
  const isMerchantRankingList = name === 'merchantRankingList';
  const isBtcVoucherRankingList = btcRankingLists.includes(name);
  const length = R.length(data);
  const halfLength = Math.ceil(length / 2);

  const secondColWidth = !isMobile && isMerchantRankingList ? 190 : 100;
  const thirdColWidth = isMobile || isMerchantRankingList ? 115 : 210;

  const columns = [
    {
      Header: <FormattedMessage {...messages.ranking} />,
      accessor: 'ranking',
      width: isMobile ? 50 : 85,
    },
    {
      Header: (
        <FormattedMessage
          {...messages[isMerchantRankingList ? 'platformName' : 'cityPeople']}
        />
      ),
      accessor: isMerchantRankingList ? 'intl' : 'people',
      Cell: ({ value }) => (
        <Ellipsis width={secondColWidth - 15} test="1">
          {typeof value === 'string' ? value : <FormattedMessage {...value} />}
        </Ellipsis>
      ),
      width: secondColWidth,
    },
    {
      Header: (
        <FormattedMessage
          {...messages[
            isMerchantRankingList
              ? 'amount'
              : isBtcVoucherRankingList
              ? 'totalVoucherPurchase'
              : 'orderQuantity'
          ]}
        />
      ),
      accessor: isMerchantRankingList ? 'amount' : 'quantity',
      Cell: ({ value }) =>
        value ? <DataValue width={thirdColWidth - 15} value={value} /> : '',
      width: thirdColWidth,
    },
  ];

  const getTrProps = (_, { original: { myScore } }) =>
    myScore ? { className: '-active' } : {};

  return (
    <Modal onClose={isMobile && onClose} isBlurred>
      <MobileContainer>
        <FormWrapper isMerchantRankingList={isMerchantRankingList}>
          <Form>
            <FormattedMessage {...messages[name]} tagName="h4" />
            {isMobile ? null : (
              <CloseButton onClick={onClose}>
                <IconClose />
              </CloseButton>
            )}
            <FormContent>
              {!isMerchantRankingList && !isMobile ? (
                <Fragment>
                  <Score
                    data={R.take(halfLength, data)}
                    pageSize={halfLength}
                    columns={columns}
                    getTrProps={getTrProps}
                  />
                  <Score
                    data={R.drop(halfLength, data)}
                    pageSize={halfLength}
                    columns={columns}
                    getTrProps={getTrProps}
                  />
                </Fragment>
              ) : (
                <Score
                  data={data}
                  pageSize={length}
                  columns={columns}
                  getTrProps={getTrProps}
                />
              )}
            </FormContent>
          </Form>
        </FormWrapper>
      </MobileContainer>
    </Modal>
  );
};

export default ScoreModal;
