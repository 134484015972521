import React from 'react';
import { createPortal } from 'react-dom';
import styled, { css } from 'styled-components/macro';
import { ifProp } from 'styled-tools';
import { FormattedMessage } from 'react-intl';
import ScrollBar from 'react-scrollbar';
import { isMobile } from 'react-device-detect';

import { ReactComponent as IconClose } from '@/icons/ic_x.svg';

import messages from './messages';

const ContentWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: ${ifProp('isBlurred', 'rgba(0, 0, 0, 0.75)', '#072d5d')};
  color: #fff;
  z-index: 1000;
  padding-top: 68px;

  .scrollarea {
    height: 100%;
  }
`;

const ContentBody = styled.div`
  font-size: 17px;
  line-height: 1.3;
  font-weight: 300;

  padding: 0 10px 20px;
  overflow: hidden;
  width: auto;
  margin: 0 auto;

  h2 {
    font-size: 42px;
    line-height: 49px;
    font-weight: normal;

    margin: 0 0 31px;
  }

  h3 {
    font-size: 24px;
    margin: 32px 0 11px;
  }

  a {
    color: #5dc6f6;
  }
`;

const Close = styled.div`
  font-size: 14px;
  line-height: 20px;

  position: fixed;
  z-index: 10;
  display: flex;

  align-items: center;
  justify-content: center;

  color: #5dc6f6;
  cursor: pointer;

  & > svg {
    width: 20px;
    height: 20px;
  }

  ${isMobile
    ? css`
        line-height: 16px;
        right: 20px;
        top: 20px;
        flex-direction: row-reverse;

        & > svg {
          margin-left: 8px;
        }
      `
    : css`
        line-height: 20px;
        right: 60px;
        top: 64px;
        flex-direction: column;

        & > svg {
          margin-bottom: 9px;
        }
      `}

  &:hover {
    color: #fff;
  }
`;

const Modal = ({ onClose, children, isBlurred }) =>
  createPortal(
    <ContentWrapper isBlurred={isBlurred}>
      <ScrollBar>
        <ContentBody>{children}</ContentBody>
      </ScrollBar>
      {onClose ? (
        <Close onClick={onClose}>
          <IconClose />
          <FormattedMessage {...messages.closeBtn} />
        </Close>
      ) : null}
    </ContentWrapper>,
    document.getElementById('root'),
  );

export default Modal;
