import React, { Fragment } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import styled from 'styled-components/macro';
import { isMobile } from 'react-device-detect';

import titleMessages from '~/components/CityIcon/messages';

import Modal from '../Modal';
import Form from '../Form';
import messages from './messages';

const StyledContainer = styled.div`
  font-size: 32px;
  margin-top: 50px;
`;

const MobileContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  min-height: calc(100vh - 90px);
`;

const FormWrapper = styled.div`
  max-width: 450px;
`;

const FormContent = styled.div`
  font-size: 15px;

  text-align: left;

  margin: 5px 10px;

  h1 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
`;

const DontShowLabel = styled.label`
  font-size: 12px;

  display: flex;

  align-items: center;
  margin-top: 20px;

  color: #fff;

  input {
    margin: 0 10px 0 0;
  }
`;

const Link = styled.div`
  margin-top: 15px;
  color: #55b4e0;
`;

const ClientAppsModal = ({
  onClose,
  message,
  goTo,
  toggleShowDialog,
  isLoading,
}) => {
  return (
    !isLoading && (
      <Modal onClose={onClose} isBlurred={isMobile}>
        {isMobile ? (
          <MobileContainer>
            <FormWrapper>
              <Form>
                <FormContent>
                  <FormattedHTMLMessage
                    {...titleMessages[message.toLowerCase()]}
                    tagName="h1"
                  />
                  <FormattedHTMLMessage {...messages[message]} />
                  <Fragment>
                    <Link onClick={goTo}>
                      <FormattedHTMLMessage {...messages.goToPage} />
                    </Link>
                    <DontShowLabel>
                      <input
                        type="checkbox"
                        onChange={e =>
                          toggleShowDialog({
                            [message]: e.target.checked,
                          })
                        }
                      />
                      <FormattedHTMLMessage {...messages.dontShow} />
                    </DontShowLabel>
                  </Fragment>
                </FormContent>
              </Form>
            </FormWrapper>
          </MobileContainer>
        ) : (
          <Fragment>
            <StyledContainer>
              <FormattedHTMLMessage {...messages[message]} />
            </StyledContainer>
          </Fragment>
        )}
      </Modal>
    )
  );
};

export default ClientAppsModal;
