/*
 * ClientAppsModal Messages
 *
 * This contains all the strings for the ClientAppsModal component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  JEMJAR: `JemJar rewards consumers for offline shopping.JemJar adds the missing link between offline shopping and online rewards! It’s a digital rewards loyalty program that provides incentives to consumers who make purchases at offline locations. Users shop at participating merchants and earn Bitcoin for spending money and bringing in referrals.
  Release date: end of December 2018.`,
  GAMES: `Power Kingdoms is a game platform where users receive profit rewards for playing games.
  Power Kingdoms is an online platform that distributes digital rewards to gamers. By purchasing and playing games, as well as inviting new players, users capitalize on online entertainment and get rewarded in digital tokens.
  Release date: beginning of December 2018.
  `,
  WENDA: `Wenda is a market research platform that lets merchants reach out to consumers in effective and innovative ways. Merchants offer non-consumption tasks like surveys, ad campaigns, questionnaires, etc., and users get digital rewards for completing them.
  Release date: end of December 2018.
  `,
  FSB: `Free Shopping Bus is a service that enables offline merchants to attract consumers by sharing profits with them from sales of products and services. Consumers enrolled in the Free Shopping Bus loyalty program earn digital rewards for their spending at offline locations.
  Release date: beginning of December 2018.
  `,
  MS: `GRC is a membership system that lets users, including merchants, consumers, and agents, get profit rewards based on their scores. Scores can be increased and accumulated by completing tasks and paying license fees.
  Release date: beginning of December 2018.
  `,
  CHAT: 'CHAT',
  // Monopoly platform explanation
  MONOPOLY:
    'GRC • Monopoly is an MMO board game that is being developed and is planning to be launched in 2019. In this game world, citizens can use the points earned to invest virtual real estate, and collect rent from other players; the rent can be converted into bitcoin. The commercial value of the game is that when the number of players increases to a certain amount, the citizens can have the opportunity to rent the real estate, in the game, to a real merchant to publish advertisements or coupons. Release date: Late of May 2019',
  // Deal plus platform explanation
  DEAL_PLUS:
    'Deal+ rewards consumers with reward point for purchasing online product. Reward points can be used to exchange mBTC rewards in Shopping Bus 4 All and increase GRC score. mBTC rewards will be distribute according to GRC rules in membership system.',
  goToPage: 'Go to page',
  dontShow: 'Do not show this window and follow directly',
});
